// https://www.npmjs.com/package/slick-carousel
import $ from 'jquery';

$(($) => {
	if ($('.js-GenericSlideshow > div').length > 1) {
		$('.js-GenericSlideshow').slick({
			arrows: true,
			dots: true,
			fade: true,
			autoplay: false,
			nextArrow:
				'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon"></span></div>',
			prevArrow:
				'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"></span></div>',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						arrows: false,
					},
				},
			],
		});
	}

	$('.js-GallerySlideshow-small')
		.slick({
			arrows: true,

			autoplay: false,
			slidesToShow: 4,
			nextArrow:
				'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon"></span></div>',
			prevArrow:
				'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"></span></div>',

			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 768,
					settings: {
						centerMode: true,
						centerPadding: '12%',
						arrows: false,
						slidesToShow: 1,
					},
				},
			],
		})
		.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
			if (currentSlide !== nextSlide) {
				console.log('Curr - ' + currentSlide);
				console.log('Next - ' + nextSlide);
				const diff = nextSlide - currentSlide;
				if (diff === 1 || diff < -1) {
					document
						.querySelectorAll('.slick-center + .slick-cloned')
						.forEach((next) => {
							setTimeout(() =>
								next.classList.add(
									'slick-current',
									'slick-center',
								),
							);
						});
				}
			}
		});

	$('.js-GallerySlideshow-large')
		.slick({
			arrows: true,
			autoplay: false,
			slidesToShow: 3,
			centerMode: true,
			centerPadding: '0px',
			nextArrow:
				'<button class="large-gallery-arrow slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon"></span></div>',
			prevArrow:
				'<button class="large-gallery-arrow slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"></span></div>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '20vw',
						arrows: false,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '10vw',
					},
				},
			],
		})
		.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
			if (currentSlide !== nextSlide) {
				console.log('Curr - ' + currentSlide);
				console.log('Next - ' + nextSlide);
				const diff = nextSlide - currentSlide;
				if (diff === 1 || diff < -1) {
					document
						.querySelectorAll('.slick-center + .slick-cloned')
						.forEach((next) => {
							setTimeout(() =>
								next.classList.add(
									'slick-current',
									'slick-center',
								),
							);
						});
				}
			}
		});

	function addOrRemoveNonSliderClasses() {
		$('.js-GallerySlideshow-small').each(function () {
			if (
				(window.matchMedia('(min-width: 1440px)').matches &&
					$(this).find('.gallery__item').length < 6) ||
				(window.matchMedia('(max-width: 1439px)').matches &&
					$(this).find('.gallery__item').length < 4) ||
				(window.matchMedia('(max-width: 767px)').matches &&
					$(this).find('.gallery__item').length < 2)
			) {
				$(this).addClass('-non-slider');
			} else {
				$(this).removeClass('-non-slider');
			}
		});

		$('.js-GallerySlideshow-large').each(function () {
			if (
				(window.matchMedia('(min-width: 1752px)').matches &&
					$(this).find('.gallery__item').length < 4) ||
				(window.matchMedia('(max-width: 1751px)').matches &&
					$(this).find('.gallery__item').length < 3) ||
				(window.matchMedia('(max-width: 767px)').matches &&
					$(this).find('.gallery__item').length < 2)
			) {
				$(this).addClass('-non-slider');
			} else {
				$(this).removeClass('-non-slider');
			}
		});
	}

	$(window).on('resize', function (e) {
		addOrRemoveNonSliderClasses();
	});

	addOrRemoveNonSliderClasses();

	$('.js-recentNewsSlider').slick({
		arrows: true,
		autoplay: false,
		slidesToShow: 5,
		nextArrow:
			'<button class="slick-next slick-arrow carousel__slickArrow" aria-label="Next" type="button"><span class="icon"></span></div>',
		prevArrow:
			'<button class="slick-prev slick-arrow carousel__slickArrow" aria-label="Prev" type="button"><span class="icon"></span></div>',

		responsive: [
			{
				breakpoint: 1752,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 1220,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '6vw',
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 450,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '6vw',
					slidesToShow: 1,
				},
			},
		],
	});
});
