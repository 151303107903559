import $ from 'jquery';
$(function ($) {
	$(window).on('click', function () {
		closeModal();
	});

	$('.modal__backBtn').on('click', function () {
		closeModal();
	});

	function closeModal() {
		$('.modal').removeClass('show-modal');
		$('.subNav').css('opacity', '100%');
		$('.pageHeader').css('z-index', '999');
		$('.topHeader').css('z-index', '1000');
		$('.mobile-app-bar').css('z-index', '99999');
	}

	$('.modal__trigger').on('click', function () {
		$(this).siblings().toggleClass('show-modal');
		$('.subNav').css('opacity', '0%');
		$('.pageHeader').css('z-index', '99');
		$('.topHeader').css('z-index', '100');
		$('.mobile-app-bar').css('z-index', '-1');
	});

	$('.modal__trigger, .modal__content').on('click', function (event) {
		event.stopPropagation();
	});

	function revealLabel(ID) {
		let label = $('#' + ID);
		label.removeClass('-hidden');
	}

	function resetLabels() {
		let labels = $('.socialShares__shareToText');
		labels.addClass('-hidden');
	}

	$('.js-displayText')
		.on('mouseover', function () {
			revealLabel($(this).data('linked-media'));
		})
		.on('mouseout', function () {
			resetLabels();
		});

	$('[data-section-size]').each(function () {
		let $sectionID = $(this).attr('data-section-size');
		let $sectionContainer = $(`[data-section-size="${$sectionID}"]`);
		let $fillerCardTemplates = $(`[data-template-size="${$sectionID}"]`);
		$fillerCardTemplates.each(function () {
			const $templateContent = $(this).html();
			$sectionContainer.append($templateContent);
		});
	});

	showOrHideFillers();

	$(window).on('resize', function () {
		showOrHideFillers();
	});

	function showOrHideFillers() {
		let $sectionContainers = $('[data-section-size]');
		$('[data-template-id]').addClass('-hidden');
		$sectionContainers.each(function () {
			let $containerSize = $(this).attr('data-section-size');
			let $apiCardCount = $(this).find(
				'.expoDealCard__container:not([data-template-id])',
			).length;
			let $cardsToFill = 0;
			switch ($containerSize) {
				case '30': {
					break;
				}
				case '20': {
					if (window.matchMedia('(min-width: 768px)').matches) {
						$cardsToFill = $apiCardCount % 2;
						for (let i = 1; i < $cardsToFill + 1; i++) {
							$(`[data-section-size="${$containerSize}"]`)
								.find(`[data-template-id="${i}"]`)
								.removeClass('-hidden');
						}
					}
					break;
				}
				case '10': {
					if (window.matchMedia('(min-width: 768px)').matches) {
						switch ($apiCardCount % 3) {
							case 0: {
								$cardsToFill = 0;
								break;
							}
							case 1: {
								$cardsToFill = 2;
								break;
							}
							case 2: {
								$cardsToFill = 1;
								break;
							}
						}

						for (let i = 0; i < $cardsToFill; i++) {
							$(
								`[data-template-id="${$containerSize}-${
									i + 1
								}"]`,
							).removeClass('-hidden');
						}
					}
					break;
				}
				case '0': {
					if (window.matchMedia('(min-width: 768px)').matches) {
						switch ($apiCardCount % 3) {
							case 0: {
								$cardsToFill = 0;
								break;
							}
							case 1: {
								$cardsToFill = 2;
								break;
							}
							case 2: {
								$cardsToFill = 1;
								break;
							}
						}
					} else {
						switch ($apiCardCount % 2) {
							case 0: {
								$cardsToFill = 0;
								break;
							}
							case 1: {
								$cardsToFill = 1;
								break;
							}
						}
					}

					for (let i = 0; i < $cardsToFill; i++) {
						$(
							`[data-template-id="${$containerSize}-${i + 1}"]`,
						).removeClass('-hidden');
					}
					break;
				}
			}
		});
	}
});
