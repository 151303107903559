import $ from 'jquery';

$(($) => {
	//HEADER POPUP (menu and search)

	// Close popup when clicking outside elsewhere on the page
	$(document).on('click', function () {
		$('.headerPopup').removeClass('-isOpen');
	});

	$('.headerPopup').on('click', function (e) {
		e.stopPropagation();
	});
	function handleStickyHeaderScrolled() {
		if (
			document.body.scrollTop > 80 ||
			document.documentElement.scrollTop > 80
		) {
			$('.pageHeader').attr('stuck', 'true');
		} else {
			$('.pageHeader').removeAttr('stuck');
		}
	}
	$(window).on('scroll', function () {
		var windowScrollTop = $(window).scrollTop();
		handleStickyHeaderScrolled();

		if (!$('.secondaryMenu__container').length) {
			return;
		}
		var elementOffsetTop = $('.secondaryMenu__container').offset().top;
		var elementHeight = $('.secondaryMenu__container').height();

		if (windowScrollTop > elementOffsetTop + elementHeight + 40) {
			$('.secondaryMenu').attr('stuck', 'true');
		} else {
			$('.secondaryMenu').removeAttr('stuck');
		}
	});

	//popup
	$('.js-toggle-headerPopup').on('click', function (e) {
		e.stopPropagation();
		var $popup = $('.headerPopup').first();
		if ($popup.hasClass('-isOpen')) {
			$popup.removeClass('-isOpen');
		} else {
			$popup.addClass('-isOpen');
		}
	});

	const $megaMenu = $('.megaMenu');
	const $megaMenuNav = $('.megaMenu__nav');
	const $megaMenuFeatured = $('.megaMenu__featured');
	const $sheen = $('.sheen');

	$(window).on('click', function () {
		$megaMenu.addClass('-inactive');
		$sheen.addClass('-inactive');
	});

	$megaMenu.on('click', function (event) {
		event.stopPropagation();
	});

	$('.mainNav__item.-hasChildren > .mainNav__link').on('click', function (e) {
		e.preventDefault();
		e.stopPropagation();

		$megaMenu.toggleClass('-inactive');
		$sheen.toggleClass('-inactive');

		let $navItems = $(this).siblings('.megaMenu__list').html();
		let $featuredItems = $(this).siblings('.megaMenu__featuredList').html();

		$megaMenuNav.html($navItems);
		$megaMenuFeatured.html($featuredItems);

		console.log($navItems);
	});

	if ($('.js-SubNav').length > 0) {
		const observer = new IntersectionObserver(
			([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
			{ threshold: [1] },
		);
		observer.observe(document.querySelector('.js-SubNav'));
	}

	//TODO: Do we still need this?

	function externalToggler() {
		$('.JS-externalToggle').toggleClass('-is-active');
		$('.externalLinks').toggleClass('-is-active');
	}

	$('.JS-externalToggle').on('click keyup', function (event) {
		if (event.keyCode === 13) {
			externalToggler();
		} else if (event.handleObj.type === 'click') {
			externalToggler();
		}
	});
});
